<template>
  <div class="mt-3">
    <p class="mb-2 font-weight-bold font-large-3 text-center">
      Change Password
    </p>
    <b-row class="justify-content-md-center">
      <b-col lg="6" class="d-flex align-items-center">
        <b-col>
          <b-card class="mt-2">
            <b-card-text class="text-left font-small-3 ">
              Strong password required. Enter 8-256 characters. Do not include common words or names.
            </b-card-text>
            <b-form>
                
            </b-form>
          </b-card>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
